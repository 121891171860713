// hooks/useFavorites.js
import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import config from '../config';

export const useFavorites = () => {
  const [favorites, setFavorites] = useState([]);
  const { user } = useAuth();

  const fetchFavorites = useCallback(async () => {
    if (!user) {
      setFavorites([]);
      return;
    }
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${config.REACT_APP_API_URL}/favorites`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        const favoritesWithDetails = await Promise.all(data.favorites.map(async (favorite) => {
          const paperResponse = await fetch(`${config.REACT_APP_API_URL}/paper/${favorite.paper_id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (paperResponse.ok) {
            const paperData = await paperResponse.json();
            return { ...favorite, ...paperData };
          }
          return favorite;
        }));
        
        favoritesWithDetails.sort((a, b) => new Date(b.added_at) - new Date(a.added_at));
        setFavorites(favoritesWithDetails);
      } else {
        setFavorites([]);
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
      setFavorites([]);
    }
  }, [user]);

  const onRemoveFavorite = async (paperId) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No authentication token found. Unable to remove favorite.');
      return;
    }
    try {
      const response = await fetch(`${config.REACT_APP_API_URL}/favorites/${paperId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        setFavorites(prevFavorites => prevFavorites.filter(fav => fav.paper_id !== paperId));
        console.log('Favorite removed successfully');
      } else {
        console.error('Failed to remove favorite. Status:', response.status);
        const errorText = await response.text();
        console.error('Error response:', errorText);
      }
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchFavorites();
    }
  }, [fetchFavorites, user]);

  return { favorites, fetchFavorites, onRemoveFavorite };
};