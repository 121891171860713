import React, { useState, useEffect, useCallback } from 'react';
import { StarIcon } from '@heroicons/react/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/outline';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import LoginRegisterModal from '../LoginRegisterModal';

const FavoriteButton = ({ paperId }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { user } = useAuth();

  const fetchFavoriteStatus = useCallback(async () => {
    if (!!user) {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('No authentication token found');
          return;
        }
        const response = await fetch(`${config.REACT_APP_API_URL}/favorites`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const favoritesData = await response.json();
          setIsFavorite(favoritesData.favorites.some(fav => fav.paper_id === paperId));
        } else {
          console.error('Failed to fetch favorite status. Status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching favorite status:', error);
      }
    }
  }, [user, paperId]);

  useEffect(() => {
    fetchFavoriteStatus();
  }, [fetchFavoriteStatus]);

  const toggleFavorite = useCallback(async () => {
    if (!!!user) {
      setShowLoginModal(true);
      return;
    }

    console.log({user})
    const newFavoriteStatus = !isFavorite;
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No authentication token found');
        return;
      }
      const response = await fetch(`${config.REACT_APP_API_URL}/favorites/${paperId}`, {
        method: newFavoriteStatus ? 'POST' : 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.ok) {
        setIsFavorite(newFavoriteStatus);
      } else {
        console.error('Failed to update favorite status. Status:', response.status);
        const errorText = await response.text();
        console.error('Error response:', errorText);
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  }, [user, isFavorite, paperId]);

  return (
    <>
      <button
        onClick={toggleFavorite}
        className="ml-4 text-yellow-500 hover:text-yellow-400 transition-colors"
        title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
        aria-label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
      >
        {isFavorite ? (
          <StarIcon className="h-7 w-7" />
        ) : (
          <StarIconOutline className="h-7 w-7 text-gray-300" />
        )}
      </button>
      <LoginRegisterModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        text="To favorite this article, please login"
      />
    </>
  );
};

export default FavoriteButton;