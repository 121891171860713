import React from 'react';
import Card from './Card';
import Table from './Table';

const PaperInformation = ({ paper }) => (
  <Card title="Paper Information">
    <Table 
      data={{
        "Paper ID": { id: paper.paper_id, url: `https://arxiv.org/abs/${paper.paper_id}` },
        "Publication Date": paper.publication_date ? new Date(paper.publication_date).toLocaleDateString() : 'N/A',
        "Source": paper.source,
        "Category": paper.category,
        "Subcategory": paper.subcategory || "N/A"
      }}
      applyBlur={false}
    />
    <h3 className="font-semibold mb-2 text-gray-700">Authors</h3>
    <ul className="list-disc list-inside text-gray-600">
      {paper.authors && paper.authors.length > 0 ?
        paper.authors.map((author, index) => (
          <li key={index}>{author}</li>
        ))
        : <li>No authors listed</li>
      }
    </ul>
  </Card>
);

export default PaperInformation;