import React from 'react';
import { Flame, Clock, Calendar, CalendarDays, Infinity } from 'lucide-react';
import { Badge } from './ui/badge';
import WhatsHotTooltip from './WhatsHotTooltip';

const WhatsHotFilter = ({ selectedRange, onRangeChange }) => {
  const ranges = [
    { value: 'today', label: 'Today', icon: Clock },
    { value: 'this-week', label: 'This Week', icon: Calendar },
    { value: 'this-month', label: 'This Month', icon: CalendarDays },
    { value: 'all-time', label: 'All Time', icon: Infinity },
  ];

  return (
    <div className="flex flex-col">
      <h2 className="text-lg font-semibold mb-2 flex items-center">
        <Flame className="mr-2 text-orange-500" /> What's Hot <WhatsHotTooltip />
      </h2>
      <div className="flex flex-wrap gap-2">
        {ranges.map((range) => (
          <Badge 
            key={range.value}
            active={selectedRange === range.value}
            onClick={() => onRangeChange(range.value)}
          >
            <range.icon className="w-4 h-4 mr-1" />
            {range.label}
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default WhatsHotFilter;