import React, { useEffect, useCallback, useRef } from 'react';
import PaperItem from './PaperItem';

const PaperList = ({ papers, totalCount, isLoading, error, onLoadMore, hasMore }) => {
  const observer = useRef();
  const lastPaperElementRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        onLoadMore();
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore, onLoadMore]);

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const formatTotalCount = (count) => {
    if (count < 1000) return count.toString();
    if (count < 10000) return "1,000+";
    if (count < 100000) return "10,000+";
    if (count < 1000000) return "100,000+";
    if (count < 10000000) return "1M+";
    return "10M+";
  };

  const hasValidMetrics = (paper) => {
    return paper.standardized_metrics && paper.standardized_metrics.length > 0;
  };

  const DUMMY_ITEM_COUNT = 4;

  const renderDummyItems = () => {
    return Array.from({ length: DUMMY_ITEM_COUNT }, (_, index) => (
      <PaperItem key={`dummy-${index}`} isLoading={true} />
    ));
  };

  return (
    <div className="mt-8">
      <h2 className="text-2xl font-semibold mb-6">
        Papers <span className="text-xl font-thin mb-6">
          ({isLoading && papers.length === 0 ? 'Loading...' : `${formatTotalCount(totalCount)} total`})
        </span>
      </h2>
      {error && (
        <p className="text-red-600 mb-4">Error: {error}</p>
      )}
      {papers.length === 0 && !isLoading ? (
        <p className="text-gray-600">No papers found matching the selected criteria.</p>
      ) : (
        <ul className="space-y-8">
          {isLoading && papers.length === 0 ? renderDummyItems() : papers.map((paper, index) => (
            <PaperItem
              key={paper.paper_id}
              paper={paper}
              hasValidMetrics={hasValidMetrics}
              ref={index === papers.length - 1 ? lastPaperElementRef : null}
            />
          ))}
        </ul>
      )}
      {isLoading && papers.length > 0 && (
        <p className="text-gray-600 mt-4">Loading more papers...</p>
      )}
      {!isLoading && !hasMore && papers.length > 0 && (
        <p className="text-gray-600 mt-4">No more papers to load.</p>
      )}
    </div>
  );
};

export default PaperList;