import React, { forwardRef } from 'react';
import { Badge } from './ui/badge';

const PaperItem = forwardRef(({ paper, isLoading }, ref) => {
  const hasValidMetrics = (paper) => {
    return paper && paper.standardized_metrics && paper.standardized_metrics.length > 0;
  };

  const loadingClass = isLoading ? 'animate-pulse blur-sm' : '';

  return (
    <li ref={ref} className={`bg-white border border-gray-200 p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 ${loadingClass}`}>
      <a href={isLoading ? '#' : `/paper/${paper?.paper_id}`} className="block cursor-pointer">
        <h3 className="font-semibold text-xl text-blue-600 hover:text-blue-800 transition-colors duration-300 mb-2">
          {isLoading ? 'Loading Paper Title...' : paper.title}
        </h3>
        <div className="flex flex-wrap items-center text-sm text-gray-600 mb-3">
          <span className="mr-4">Authors: {isLoading ? 'Loading...' : (paper.authors ? paper.authors.join(', ') : 'N/A')}</span>
          <span className="mr-4">Published: {isLoading ? 'Loading...' : (paper.publication_date ? new Date(paper.publication_date).toLocaleDateString() : 'N/A')}</span>
          <span>Relevance Score: {isLoading ? 'Loading...' : (paper.relevance_score || 'N/A')}/10</span>
        </div>
        {(isLoading || hasValidMetrics(paper)) && (
          <div>
            <h4 className="font-light mb-2 text-gray-700">Top Evaluation Metrics:</h4>
            <div className="flex flex-wrap gap-2">
              {isLoading ? (
                Array(4).fill().map((_, idx) => (
                  <div key={idx} className="flex items-center bg-gray-100 px-3 py-1 rounded-full">
                    <Badge variant="secondary" className="mr-2 text-xs">Loading</Badge>
                    <span className="text-sm font-medium">0.0000</span>
                  </div>
                ))
              ) : (
                paper.standardized_metrics && paper.standardized_metrics.slice(0, 4).map((metric, idx) => (
                  <div key={idx} className="flex items-center bg-gray-100 px-3 py-1 rounded-full">
                    <Badge variant="secondary" className="mr-2 text-xs">{metric.name}</Badge>
                    <span className="text-sm font-medium">{metric.value.toFixed(4)}</span>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </a>
      {!isLoading && (
        <div className="mt-4 flex space-x-4">
          {paper.github_repository && (
            <a href={paper.github_repository} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-600 hover:text-blue-800">GitHub</a>
          )}
          {paper.source === 'arXiv' && (
            <a href={`https://arxiv.org/abs/${paper.paper_id}`} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-600 hover:text-blue-800">arXiv</a>
          )}
        </div>
      )}
    </li>
  );
});

export default PaperItem;