import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import config from '../config';

const Register = () => {
  const [formData, setFormData] = useState({
    email: '',
    full_name: '',
    password: '',
    accept_tos: false,
    accept_messages: false
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formError, setFormError] = useState('');
  const { login, error, clearError, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    clearError();
  }, [clearError]);

  const validateForm = () => {
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setFormError('Email is invalid');
      return false;
    }
    if (formData.full_name.length < 3) {
      setFormError('Full name must be at least 3 characters long');
      return false;
    }
    if (formData.password.length < 8) {
      setFormError('Password must be at least 8 characters long');
      return false;
    }
    if (formData.password !== confirmPassword) {
      setFormError('Passwords do not match');
      return false;
    }
    if (!formData.accept_tos) {
      setFormError('You must accept the Terms of Service');
      return false;
    }
    setFormError('');
    return true;
  };

  const getPasswordStrength = (password) => {
    const strengthChecks = {
      length: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    const strength = Object.values(strengthChecks).filter(Boolean).length;
    return ['Weak', 'Fair', 'Good', 'Strong', 'Very Strong'][strength - 1] || 'Very Weak';
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const registerData = {
        ...formData,
      };
      await axios.post(`${config.REACT_APP_API_URL}/register`, registerData);
      
      try {
        const loginResponse = await axios.post(`${config.REACT_APP_API_URL}/token`, 
          new URLSearchParams({
            'username': formData.email,
            'password': formData.password
          }),
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          }
        );
        
        const { access_token } = loginResponse.data;
        await login(access_token, false);
        navigate('/');
      } catch (loginError) {
        console.error('Error logging in:', loginError);
        setFormError('Registration successful, but there was an error logging in. Please try logging in manually.');
      }
    } catch (error) {
      console.error('Registration error:', error.response?.data);
      if (error.response?.data?.detail) {
        if (Array.isArray(error.response.data.detail)) {
          setFormError(error.response.data.detail.map(err => err.msg).join(', '));
        } else {
          setFormError(error.response.data.detail);
        }
      } else {
        setFormError('Registration failed. Please try again.');
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-6 text-center">Register</h2>
      {(error || formError) && <p className="text-red-500 text-sm mb-4 text-center">{error || formError}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="full_name" className="block text-gray-700 text-sm font-bold mb-2">Full Name:</label>
          <input
            type="text"
            id="full_name"
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
          {formData.password && (
            <p className="text-sm mt-1">Strength: <span className={`font-bold ${getPasswordStrength(formData.password) === 'Weak' ? 'text-red-500' : getPasswordStrength(formData.password) === 'Fair' ? 'text-yellow-500' : 'text-green-500'}`}>{getPasswordStrength(formData.password)}</span></p>
          )}
        </div>
        <div className="mb-6">
          <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="accept_tos"
              checked={formData.accept_tos}
              onChange={handleChange}
              className="mr-2"
              required
            />
            <span className="text-sm">I accept the <a href="/terms" className="text-blue-500 hover:text-blue-700">Terms of Service</a></span>
          </label>
        </div>
        <div className="mb-6">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="accept_messages"
              checked={formData.accept_messages}
              onChange={handleChange}
              className="mr-2"
            />
            <span className="text-sm">I agree to receive updates</span>
          </label>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </button>
        </div>
      </form>
      <p className="mt-4 text-center text-sm">
        Already have an account? <Link to="/login" className="font-bold text-blue-500 hover:text-blue-800">Login here</Link>
      </p>
    </div>
  );
};

export default Register;