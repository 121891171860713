import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../config';
import PaperInformation from './PaperInformation';
import Methodology from './Methodology';
import TechnicalDetails from './TechnicalDetails';
import PerformanceSummary from './PerformanceSummary';
import LimitationsAndFutureWork from './LimitationsAndFutureWork';
import DataAvailability from './DataAvailability';
import ScoreCard from './ScoreCard';
//import DebugSection from './DebugSection';
import FavoriteButton from './FavoriteButton';
import { useAuth } from '../../context/AuthContext';
import WhatsHotTooltip from '../WhatsHotTooltip';

const PaperDetails = () => {
  const [paper, setPaper] = useState(null);
  const [error, setError] = useState(null);
  const { paperId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    const fetchPaperDetails = async () => {
      try {
        const response = await fetch(`${config.REACT_APP_API_URL}/paper/${paperId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setPaper(data.paper);
      } catch (error) {
        console.error('Error fetching paper details:', error);
        setError(error.message);
      }
    };

    fetchPaperDetails();
  }, [paperId]);

  if (error) {
    return <div className="text-center mt-8 text-red-600">Error: {error}</div>;
  }

  if (!paper) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <div className="w-full">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-4">
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            aria-label="Go back to previous page"
          >
            &larr; Back
          </button>
        </div>
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-thin text-gray-800 flex-grow">{paper.title || 'Untitled Paper'}</h1>
          <FavoriteButton paperId={paperId} />
        </div>
        <div className="bg-gray-100 rounded-lg shadow-md p-8">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6 w-full">
            <ScoreCard
              title="Rating"
              score={typeof paper.elo_rating === 'number' ? paper.elo_rating : null}
              maxScore={null}
              notes={typeof paper.battle_count === 'number' ? `Battle Count: ${paper.battle_count}` : 'Battle Count: N/A'}
              isAuthenticated={!!user}
              tooltip={<WhatsHotTooltip />}
            />
            <ScoreCard
              title="Relevance"
              score={paper.relevance_to_quantitative_trading?.score}
              maxScore={10}
              notes={paper.relevance_to_quantitative_trading?.notes}
              isAuthenticated={!!user}
            />
            <ScoreCard
              title="Implementation Complexity"
              score={paper.implementation_complexity?.score}
              maxScore={10}
              notes={paper.implementation_complexity?.notes}
              isAuthenticated={!!user}
            />
            <ScoreCard
              title="Reproducibility"
              score={paper.reproducibility?.score}
              maxScore={5}
              notes={paper.reproducibility?.notes}
              isAuthenticated={!!user}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 w-full">
            <DataAvailability dataAvailability={paper.data_availability} isAuthenticated={!!user} />
            <PerformanceSummary performanceSummary={paper.performance_summary} isAuthenticated={!!user} />
            <TechnicalDetails paper={paper} isAuthenticated={!!user} />
            <PaperInformation paper={paper} isAuthenticated={!!user} />
            <Methodology methodology={paper.methodology} isAuthenticated={!!user} />
            <LimitationsAndFutureWork limitations={paper.limitations} futureWork={paper.future_work} isAuthenticated={!!user} />
          </div>
        </div>
        {/* <DebugSection data={paper} /> */}
      </div>
    </div>
  );
};

export default PaperDetails;