import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Badge } from './ui/badge';
import LoadingBadge from './LoadingBadge';
import styles from './ModelFilter.module.css';
import config from '../config';

const ModelFilter = ({ onFilterChange, initialSelectedModels = [] }) => {
  const [models, setModels] = useState([]);
  const [selectedModels, setSelectedModels] = useState(initialSelectedModels);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [visibleCount, setVisibleCount] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const filterContainerRef = useRef(null);

  useEffect(() => {
    fetchTopModels();
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
      setVisibleCount(newIsMobile ? Math.floor(models.length / 6) : 5);
      setShowAllFilters(false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [models.length]);

  useEffect(() => {
    setSelectedModels(initialSelectedModels);
  }, [initialSelectedModels]);

  const fetchTopModels = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.REACT_APP_API_URL}/top-models`);
      const data = await response.json();
      setModels(data.top_models || []);
    } catch (error) {
      console.error('Error fetching top models:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleModel = (modelName) => {
    setSelectedModels(prev => {
      const newSelection = prev.includes(modelName)
        ? prev.filter(m => m !== modelName)
        : [modelName, ...prev];
      
      onFilterChange(newSelection);
      return newSelection;
    });
  };

  const sortedModels = useMemo(() => {
    return [...models].sort((a, b) => {
      const aSelected = selectedModels.includes(a.model);
      const bSelected = selectedModels.includes(b.model);
      if (aSelected === bSelected) {
        return selectedModels.indexOf(a.model) - selectedModels.indexOf(b.model);
      }
      return bSelected - aSelected;
    });
  }, [models, selectedModels]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setShowAllFilters(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setShowAllFilters(false);
    }
  };

  return (
    <div 
      className={`${styles.filterContainer} ${isMobile ? styles.mobile : ''} ${showAllFilters ? styles.expanded : ''}`}
      ref={filterContainerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold">Top Models</h2>
        {isMobile && sortedModels.length > visibleCount && (
          <button 
            onClick={() => setShowAllFilters(!showAllFilters)} 
            className="text-blue-500 underline text-xs"
          >
            {showAllFilters ? "hide extra" : "show all"}
          </button>
        )}
      </div>
      <div className="flex flex-wrap gap-2">
        {isLoading ? (
          Array(5).fill().map((_, index) => (
            <LoadingBadge key={index} />
          ))
        ) : (
          (showAllFilters || !isMobile ? sortedModels : sortedModels.slice(0, visibleCount)).map(({ model, count }) => (
            <Badge 
              key={model}
              active={selectedModels.includes(model)}
              onClick={() => toggleModel(model)}
            >
              {model} ({count})
            </Badge>
          ))
        )}
      </div>
    </div>
  );
};

export default ModelFilter;