import React from 'react';
import Card from './Card';
import Tag from './Tag';

const Methodology = ({ methodology }) => (
  <Card title="Methodology">
    <p><strong className="text-gray-700">Name:</strong> <span className="text-gray-600">{methodology?.name || 'N/A'}</span></p>
    <p><strong className="text-gray-700">Type:</strong> <span className="text-gray-600">{methodology?.type || 'N/A'}</span></p>
    <p><strong className="text-gray-700">Description:</strong> <span className="text-gray-600">{methodology?.description || 'No description available.'}</span></p>
    <h3 className="font-semibold mt-4 mb-2 text-gray-700">Domains</h3>
    <div>
      {methodology?.domains && methodology.domains.length > 0 ?
        methodology.domains.map((domain, index) => (
          <Tag key={index}>{domain}</Tag>
        ))
        : <p className="text-gray-600">No domains specified</p>
      }
    </div>
  </Card>
);

export default Methodology;