import React from 'react';
import Card from './Card';

const LimitationsAndFutureWork = ({ limitations, futureWork }) => (
  <Card title="Limitations and Future Work">
    <h3 className="font-semibold mb-2 text-gray-700">Limitations</h3>
    <ul className="list-disc list-inside mb-4 text-gray-600">
      {limitations && limitations.length > 0 ?
        limitations.map((limitation, index) => (
          <li key={index}>{limitation}</li>
        ))
        : <li>No limitations specified</li>
      }
    </ul>
    <h3 className="font-semibold mb-2 text-gray-700">Future Work</h3>
    <ul className="list-disc list-inside text-gray-600">
      {futureWork && futureWork.length > 0 ?
        futureWork.map((work, index) => (
          <li key={index}>{work}</li>
        ))
        : <li>No future work specified</li>
      }
    </ul>
  </Card>
);

export default LimitationsAndFutureWork;