import React from 'react';
import Card from './Card';
import Blur from '../ui/Blur';

const DataAvailability = ({ dataAvailability, isAuthenticated }) => (
  <Card title="Data Availability">
    <p><strong className="text-gray-700">Status:</strong> <span className="text-gray-600">{dataAvailability?.status || 'N/A'}</span></p>
    <Blur enabled={!isAuthenticated} hoverMessage="Login to view data availability notes">
      <p className="text-gray-600 mb-4">{dataAvailability?.notes || 'No additional notes on data availability.'}</p>
    </Blur>
    
    {!isAuthenticated && (
      <p className="mt-4 text-sm text-gray-500 italic">
        Detailed notes on data availability are accessible after logging in.
      </p>
    )}
  </Card>
);

export default DataAvailability;