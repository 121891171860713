// HomePage.js
import React, { useEffect, useCallback } from 'react';
import TopMetricsFilter from './TopMetricsFilter';
import PaperList from './PaperList';
import SearchBar from './SearchBar';
import ModelFilter from './ModelFilter';
import FavoritedArticles from './FavoritedArticles';
import WhatsHotFilter from './WhatsHotFilter';
import { usePapers } from '../hooks/usePapers';
import { useFavorites } from '../hooks/useFavorites';
import { useURLParams } from '../hooks/useURLParams';

const HomePage = () => {
  const { 
    selectedMetrics, setSelectedMetrics,
    selectedModels, setSelectedModels,
    searchTerm, setSearchTerm,
    sortBy, setSortBy,
    sortOrder, setSortOrder,
    itemsPerPage,
    selectedTimeRange, setSelectedTimeRange
  } = useURLParams();

  const { 
    papers, 
    totalCount, 
    isLoading, 
    error,
    loadMore,
    resetPapers,
    hasMore
  } = usePapers({
    selectedMetrics,
    selectedModels,
    searchTerm,
    sortBy,
    sortOrder,
    itemsPerPage,
    selectedTimeRange,
    fetchMode: 'infinite'
  });

  const { favorites, onRemoveFavorite } = useFavorites();

  useEffect(() => {
    console.log('HomePage rendered. selectedTimeRange:', selectedTimeRange);
  }, [selectedTimeRange]);

  const handleFilterChange = (newSelectedMetrics) => {
    setSelectedMetrics(newSelectedMetrics);
    resetPapers();
  };

  const handleModelFilterChange = (newSelectedModels) => {
    setSelectedModels(newSelectedModels);
    resetPapers();
  };

  const handleSearch = (newSearchTerm, newTimeRange) => {
    setSearchTerm(newSearchTerm);
    setSelectedTimeRange(newTimeRange);
    resetPapers();
  };

  const handleSortChange = (newSortBy, newSortOrder) => {
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    resetPapers();
  };
  
  const handleTimeRangeChange = (newTimeRange) => {
    console.log('Changing time range to:', newTimeRange);
    setSelectedTimeRange(newTimeRange);
    resetPapers();
  };

  const handleLoadMore = useCallback(() => {
    if (hasMore && !isLoading) {
      loadMore();
    }
  }, [hasMore, isLoading, loadMore]);

  return (
    <div className="w-full">
      <h1 className="text-3xl font-light text-gray-900 mb-8">Quant Explorer</h1>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-1/3 pr-4 mb-6 lg:mb-0">
          <div className="space-y-6">
            <div className="w-full lg:w-2/3">
              <SearchBar onSearch={handleSearch} initialSearchTerm={searchTerm} />
            </div>
            <WhatsHotFilter
              selectedRange={selectedTimeRange}
              onRangeChange={handleTimeRangeChange}
            />
            <ModelFilter
              onFilterChange={handleModelFilterChange}
              initialSelectedModels={selectedModels}
            />
            <TopMetricsFilter 
              onFilterChange={handleFilterChange} 
              initialSelectedMetrics={selectedMetrics} 
            />
            {favorites.length > 0 && (
              <FavoritedArticles favorites={favorites} onRemoveFavorite={onRemoveFavorite} />
            )}
          </div>
        </div>
        <div className="lg:w-2/3">
          <PaperList 
            papers={papers} 
            totalCount={totalCount} 
            isLoading={isLoading}
            error={error}
            hasMore={hasMore}
            onLoadMore={handleLoadMore}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;