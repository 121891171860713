import React from 'react';
import Card from './Card';
import Blur from '../ui/Blur';

const TechnicalDetails = ({ paper, isAuthenticated }) => (
  <Card title="Technical Details">
    <h3 className="font-semibold mb-2 text-gray-700">Models</h3>
    <ul className="list-disc list-inside mb-4 text-gray-600">
      {paper.models && paper.models.length > 0 ?
        paper.models.map((model, index) => (
          <li key={index}>
            <Blur enabled={!isAuthenticated} hoverMessage="Login to view model">
              {model}
            </Blur>
          </li>
        ))
        : <li>No models specified</li>
      }
    </ul>
    <h3 className="font-semibold mb-2 text-gray-700">Evaluation Metrics</h3>
    <ul className="list-disc list-inside mb-4 text-gray-600">
      {paper.evaluation_metrics && paper.evaluation_metrics.length > 0 ?
        paper.evaluation_metrics.map((metric, index) => (
          <li key={index}>
            <Blur enabled={!isAuthenticated} hoverMessage="Login to view metric">
              {metric}
            </Blur>
          </li>
        ))
        : <li>No evaluation metrics specified</li>
      }
    </ul>
    <h3 className="font-semibold mb-2 text-gray-700">Objective Functions</h3>
    <ul className="list-disc list-inside mb-4 text-gray-600">
      {paper.objective_functions && paper.objective_functions.length > 0 ?
        paper.objective_functions.map((func, index) => (
          <li key={index}>
            <Blur enabled={!isAuthenticated} hoverMessage="Login to view objective function">
              {func}
            </Blur>
          </li>
        ))
        : <li>No objective functions specified</li>
      }
    </ul>
    <h3 className="font-semibold mb-2 text-gray-700">Problem Types</h3>
    <ul className="list-disc list-inside text-gray-600">
      {paper.problem_types && paper.problem_types.length > 0 ?
        paper.problem_types.map((type, index) => (
          <li key={index}>
            <Blur enabled={!isAuthenticated} hoverMessage="Login to view problem type">
              {type}
            </Blur>
          </li>
        ))
        : <li>No problem types specified</li>
      }
    </ul>
  </Card>
);

export default TechnicalDetails;