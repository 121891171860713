import React from 'react';

const Terms = () => {
  return (
    <div className="terms-container max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Disclaimers</h2>
        <p className="mb-4">
          The information provided on cadenzai.net is for general informational purposes only. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.
        </p>
        <p className="mb-4">
          Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Service Availability</h2>
        <p className="mb-4">
          We do not guarantee that the service will be uninterrupted, timely, secure, or error-free. We reserve the right to withdraw or amend our service, and any service or material we provide, without notice. We will not be liable if for any reason all or any part of our service is unavailable at any time or for any period.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Privacy Policy</h2>
        <p className="mb-4">We collect and store the following information from our users:</p>
        <ul className="list-disc pl-8 mb-4">
          <li>Name</li>
          <li>Email address</li>
          <li>Account password</li>
          <li>Account Preferences</li>
        </ul>
        <p className="mb-4">
          We take the security of your information seriously. Sensitive account information, such as passwords, is encrypted using industry-standard encryption methods. We uphold the highest security standards to protect your data.
        </p>
        <p className="mb-4">
          We do not sell any user data or email addresses to third parties. Your information is used solely for the purpose of providing and improving our services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Changes to Terms</h2>
        <p className="mb-4">
          We reserve the right to modify these terms at any time. We encourage you to periodically review this page for the latest information on our terms and conditions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about these Terms and Conditions, please contact us. 
        </p>
        <p className="mb-4">
          Cadenzai, Inc. 
        </p>
        <p className="mb-4">
          support@cadenzai.net 
        </p>
      </section>
    </div>
  );
};

export default Terms;