import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    console.log('AuthContext error state changed:', error);
  }, [error]);

  const logout = useCallback(() => {
    setAuthToken(null);
    setUser(null);
    setError(null);
    console.log('Logged out successfully');
    localStorage.setItem('app-logout', Date.now().toString());
  }, []);

  const setAuthToken = (token) => {
    if (token) {
      localStorage.setItem('authToken', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem('authToken');
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  const refreshToken = useCallback(async () => {
    if (isRefreshing) {
      throw new Error('Token refresh already in progress');
    }
    setIsRefreshing(true);
    try {
      const response = await axios.post(`${config.REACT_APP_API_URL}/refresh-token`, {}, {
        withCredentials: true
      });
      const { token } = response.data;
      setAuthToken(token);
      const decodedToken = jwtDecode(token);
      setUser(decodedToken);
      console.log('Token refreshed successfully');
      return token;
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
      throw error;
    } finally {
      setIsRefreshing(false);
    }
  }, [logout, isRefreshing]);

  useEffect(() => {
    const checkAuthState = () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 > Date.now()) {
            setUser(decodedToken);
          } else {
            setUser(null);
            setAuthToken(null);
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          setUser(null);
          setAuthToken(null);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    };

    checkAuthState();

    const handleStorageChange = (e) => {
      if (e.key === 'authToken' || e.key === 'app-logout') {
        checkAuthState();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (user) {
      const tokenExp = user.exp * 1000;
      const refreshTime = tokenExp - Date.now() - (60 * 1000);
      const refreshTimer = setTimeout(refreshToken, refreshTime);
      return () => clearTimeout(refreshTimer);
    }
  }, [user, refreshToken]);

  useEffect(() => {
    let isAlreadyFetchingAccessToken = false;
    let subscribers = [];

    function onAccessTokenFetched(accessToken) {
      subscribers = subscribers.filter(callback => callback(accessToken));
    }

    function addSubscriber(callback) {
      subscribers.push(callback);
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          if (isAlreadyFetchingAccessToken) {
            return new Promise((resolve) => {
              addSubscriber((accessToken) => {
                originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                resolve(axios(originalRequest));
              });
            });
          }

          originalRequest._retry = true;
          isAlreadyFetchingAccessToken = true;

          try {
            const accessToken = await refreshToken();
            onAccessTokenFetched(accessToken);
            originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
            return axios(originalRequest);
          } catch (refreshError) {
            logout();
            subscribers = [];
            isAlreadyFetchingAccessToken = false;
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [refreshToken, logout]);

  const login = async (username, password) => {
    console.log('API URL:', config.REACT_APP_API_URL);
    console.log('Attempting login');
    try {
      const formData = new URLSearchParams();
      formData.append('username', username);
      formData.append('password', password);
  
      const response = await axios.post(`${config.REACT_APP_API_URL}/token`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
  
      console.log('Login response:', response);
  
      const { access_token } = response.data;
      setAuthToken(access_token);
  
      const decodedToken = jwtDecode(access_token);
      setUser(decodedToken);
  
      console.log('Login successful');
      setError(null);
    } catch (error) {
      console.error('Login error:', error);
      let errorMessage = 'An error occurred during login. Please try again.';
      if (error.response) {
        console.log('Error response:', error.response);
        if (error.response.status === 401) {
          errorMessage = 'Invalid username or password. Please try again.';
        } else if (error.response.data && error.response.data.detail) {
          errorMessage = error.response.data.detail;
        }
      }
      console.log('Setting error:', errorMessage);
      setError(errorMessage);
      setUser(null);
      throw error
    }
  };

  const clearError = useCallback(() => {
    console.log('Clearing error');
    setError(null);
  }, []);

  const value = {
    user,
    login,
    logout,
    loading,
    error,
    clearError,
    isAuthenticated: () => !!user,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};