// hooks/usePapers.js
import { useState, useEffect, useCallback, useRef } from 'react';
import config from '../config';

export const usePapers = ({
  selectedMetrics,
  selectedModels,
  searchTerm,
  sortBy,
  sortOrder,
  itemsPerPage,
  selectedTimeRange,
  fetchMode = 'pagination'
}) => {
  const [papers, setPapers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const isInitialMount = useRef(true);
  const currentOffset = useRef(0);

  const fetchPapers = useCallback(async (isLoadingMore = false) => {
    if (isLoading) return;
    setIsLoading(true);
    setError(null);

    const offset = isLoadingMore ? currentOffset.current : 0;

    const queryParams = new URLSearchParams([
      ...selectedMetrics.map(metric => ['metrics', metric]),
      ...selectedModels.map(model => ['models', model]),
      ['sort_by', sortBy],
      ['sort_order', sortOrder],
      ['limit', itemsPerPage.toString()],
      ['offset', offset.toString()],
      ['time_range', selectedTimeRange],
    ]);
    if (searchTerm) queryParams.append('search', searchTerm);

    try {
      const response = await fetch(`${config.REACT_APP_API_URL}/papers?${queryParams}`);
      if (!response.ok) {
        throw new Error('Failed to fetch papers');
      }
      const data = await response.json();
      
      setPapers(prevPapers => {
        if (isLoadingMore) {
          return [...prevPapers, ...data.papers];
        } else {
          return data.papers;
        }
      });
      
      setTotalCount(data.total_count);
      setHasMore(offset + data.papers.length < data.total_count);
      currentOffset.current = offset + data.papers.length;
      
      if (isLoadingMore) {
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching papers:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedMetrics, selectedModels, searchTerm, sortBy, sortOrder, itemsPerPage, selectedTimeRange]);

  const resetPapers = useCallback(() => {
    setPage(1);
    setPapers([]);
    setHasMore(true);
    currentOffset.current = 0;
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      resetPapers();
    }
    fetchPapers();
  }, [selectedMetrics, selectedModels, searchTerm, sortBy, sortOrder, itemsPerPage, selectedTimeRange, fetchPapers, resetPapers]);

  const loadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      fetchPapers(true);
    }
  }, [isLoading, hasMore, fetchPapers]);

  const goToPage = useCallback((newPage) => {
    if (fetchMode === 'pagination' && newPage !== page && newPage > 0 && newPage <= Math.ceil(totalCount / itemsPerPage)) {
      setPage(newPage);
      currentOffset.current = (newPage - 1) * itemsPerPage;
      fetchPapers();
    }
  }, [fetchMode, page, totalCount, itemsPerPage, fetchPapers]);

  return { 
    papers, 
    totalCount, 
    isLoading, 
    error,
    loadMore,
    goToPage,
    resetPapers,
    page,
    totalPages: Math.ceil(totalCount / itemsPerPage),
    hasMore
  };
};