import React from 'react';
import Card from './Card';
import Table from './Table';
import styles from './PaperDetails.module.css';

const PerformanceSummary = ({ performanceSummary, isAuthenticated }) => (
  <Card title="Performance Summary">
    <p className="mb-4 text-gray-600">{performanceSummary?.overall_description || 'No performance summary available.'}</p>
    {performanceSummary?.models_evaluated && performanceSummary.models_evaluated.length > 0 ? (
      <div>
        {performanceSummary.models_evaluated.map((model, index) => (
          <div key={index} className="mb-4">
            <h3 className="font-semibold mb-2 text-gray-700">{model.name}</h3>
            <Table
              data={model.metrics ? model.metrics.reduce((acc, metric) => {
                acc[metric.name] = metric.value;
                return acc;
              }, {}) : {}}
              isAuthenticated={isAuthenticated}
            />
          </div>
        ))}
        {!isAuthenticated && (
          <div className={styles.loginMessage}>Login to view performance metric values</div>
        )}
      </div>
    ) : (
      <p className="text-gray-600">No model performance data available.</p>
    )}
  </Card>
);

export default PerformanceSummary;