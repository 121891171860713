import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const RedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const redirectUrl = new URLSearchParams(location.search).get('redirect_url');
    
    if (isAuthenticated()) {
      if (redirectUrl) {
        navigate(decodeURIComponent(redirectUrl));
      } else {
        navigate('/'); // Default redirect if no specific URL is provided
      }
    } else {
      navigate('/login'); // Redirect to login if not authenticated
    }
  }, [isAuthenticated, location, navigate]);

  return <div>Redirecting...</div>;
};

export default RedirectHandler;