// hooks/useURLParams.js
import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useURLParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedMetrics, setSelectedMetrics] = useState(searchParams.getAll('metrics') || []);
  const [selectedModels, setSelectedModels] = useState(searchParams.getAll('models') || []);
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [sortBy, setSortBy] = useState(searchParams.get('sort_by') || 'elo_rating');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sort_order') || 'desc');
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page') || '1', 10));
  const [itemsPerPage] = useState(parseInt(searchParams.get('limit') || '10', 10));
  const [selectedTimeRange, setSelectedTimeRange] = useState(searchParams.get('timeRange') || 'this-week');

  const updateURLParams = useCallback(() => {
    const params = new URLSearchParams();
    selectedMetrics.forEach(metric => params.append('metrics', metric));
    selectedModels.forEach(model => params.append('models', model));
    if (searchTerm) params.set('search', searchTerm);
    params.set('sort_by', sortBy);
    params.set('sort_order', sortOrder);
    params.set('page', currentPage.toString());
    params.set('limit', itemsPerPage.toString());
    params.set('timeRange', selectedTimeRange);
    setSearchParams(params);
  }, [selectedMetrics, selectedModels, searchTerm, sortBy, sortOrder, currentPage, itemsPerPage, selectedTimeRange, setSearchParams]);

  useEffect(() => {
    updateURLParams();
  }, [updateURLParams]);

  useEffect(() => {
    const handlePopState = () => {
      setSelectedMetrics(searchParams.getAll('metrics'));
      setSelectedModels(searchParams.getAll('models'));
      setSearchTerm(searchParams.get('search') || '');
      setSortBy(searchParams.get('sort_by') || 'elo_rating');
      setSortOrder(searchParams.get('sort_order') || 'desc');
      setCurrentPage(parseInt(searchParams.get('page') || '1', 10));
      setSelectedTimeRange(searchParams.get('timeRange') || 'this-week');
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [searchParams]);

  const setURLParam = useCallback((key, value) => {
    if (Array.isArray(value)) {
      setSearchParams(params => {
        params.delete(key);
        value.forEach(v => params.append(key, v));
        return params;
      });
    } else if (value === null || value === undefined) {
      setSearchParams(params => {
        params.delete(key);
        return params;
      });
    } else {
      setSearchParams(params => {
        params.set(key, value.toString());
        return params;
      });
    }
  }, [setSearchParams]);

  return {
    selectedMetrics, setSelectedMetrics: (metrics) => { setSelectedMetrics(metrics); setURLParam('metrics', metrics); },
    selectedModels, setSelectedModels: (models) => { setSelectedModels(models); setURLParam('models', models); },
    searchTerm, setSearchTerm: (term) => { setSearchTerm(term); setURLParam('search', term); },
    sortBy, setSortBy: (sort) => { setSortBy(sort); setURLParam('sort_by', sort); },
    sortOrder, setSortOrder: (order) => { setSortOrder(order); setURLParam('sort_order', order); },
    currentPage, setCurrentPage: (page) => { setCurrentPage(page); setURLParam('page', page); },
    itemsPerPage,
    selectedTimeRange, setSelectedTimeRange: (range) => { setSelectedTimeRange(range); setURLParam('timeRange', range); }
  };
};