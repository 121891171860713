import * as React from "react"
import { cva } from "class-variance-authority"
import { cn } from "../../lib/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 cursor-pointer badge-base",
  {
    variants: {
      variant: {
        default:
          "badge-default border-transparent bg-primary text-primary-foreground hover:bg-gray-100 hover:bg-opacity-90 hover:shadow-sm badge-outline border-input text-foreground hover:text-accent-foreground",
        secondary:
          "badge-secondary border-transparent bg-secondary text-secondary-foreground",
        destructive:
          "badge-destructive border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80 hover:shadow-sm",
        active: "badge-active bg-blue-100 border-2 border-blue-500 text-blue-700 hover:bg-blue-200 hover:text-blue-800 hover:shadow-md",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

function Badge({
  className,
  variant,
  active,
  ...props
}) {
  return (
    <div 
      className={cn(badgeVariants({ variant: active ? "active" : variant }), className)}
      data-state={active ? "active" : "inactive"}
      {...props} 
    />
  )
}

export { Badge, badgeVariants }