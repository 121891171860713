import React from 'react';
import { Link } from 'react-router-dom';

const LoginRegisterModal = ({ show, onHide, text }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center border-b p-4">
          <h3 className="text-xl font-semibold">Login Required</h3>
          <button onClick={onHide} className="text-gray-500 hover:text-gray-700">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-4">
          {text && (
            <p className="mb-4">{text}</p>
          ) ||  ( <p className="mb-4">To view paper summaries, you must log in or register.</p>)}
         
          <div className="flex justify-around">
            <Link to="/login" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
              Login
            </Link>
            <Link to="/register" className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
              Register
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginRegisterModal;