import React from 'react';
import { Badge } from './ui/badge';

const LoadingBadge = () => {
  return (
    <Badge
      variant="secondary"
      className="animate-pulse bg-gray-200 text-transparent blur-sm"
    >
      Loading Item
    </Badge>
  );
};

export default LoadingBadge;