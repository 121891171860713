import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Blur.module.css';

const Blur = ({ children, enabled, hoverMessage = 'Login to view' }) => {
  if (!enabled) {
    return children;
  }

  return (
    <Link to="/login" className={styles.blurContainer}>
      <div className={styles.blurContent}>{children}</div>
      <div className={styles.hoverMessage}>{hoverMessage}</div>
    </Link>
  );
};

export default Blur;