import React from 'react';
import styles from './PaperDetails.module.css';
import Blur from '../ui/Blur';

const ScoreCard = ({ title, score, maxScore, notes, isAuthenticated, tooltip }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6 transition-transform hover:translate-y-[-5px]">
    <div className="flex items-center mb-2">
      <h2 className="text-lg font-semibold text-gray-700 mr-2">{title}</h2>
      {tooltip && <div className="text-gray-500">{tooltip}</div>}
    </div>
    <div className="text-3xl font-bold text-blue-600 mb-2">
      <Blur enabled={!isAuthenticated} hoverMessage="Login to view score">
        {score !== undefined && score !== null ? (
          maxScore ? (
            <>
              <span>{typeof score === 'number' ? Math.ceil(score) : score}</span>
              <span>/{maxScore}</span>
            </>
          ) : (
            <span>{typeof score === 'number' ? Math.ceil(score) : score}</span>
          )
        ) : (
          'N/A'
        )}
      </Blur>
    </div>
    <Blur enabled={!isAuthenticated} hoverMessage="Login to view details">
      <div className="text-sm text-gray-600">{notes || 'No additional notes.'}</div>
    </Blur>
  </div>
);

export default ScoreCard;