import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Badge } from './ui/badge';
import LoadingBadge from './LoadingBadge';
import styles from './TopMetricsFilter.module.css';
import config from '../config';

const TopMetricsFilter = ({ onFilterChange, initialSelectedMetrics = [] }) => {
  const [metrics, setMetrics] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState(initialSelectedMetrics);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [visibleCount, setVisibleCount] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const filterContainerRef = useRef(null);

  useEffect(() => {
    fetchTopMetrics();
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      setIsMobile(newIsMobile);
      setVisibleCount(5);
      setShowAllFilters(false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setSelectedMetrics(initialSelectedMetrics);
  }, [initialSelectedMetrics]);

  const fetchTopMetrics = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${config.REACT_APP_API_URL}/top-metrics`);
      const data = await response.json();
      setMetrics(data.metrics || []);
    } catch (error) {
      console.error('Error fetching top metrics:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMetric = (metricName) => {
    setSelectedMetrics(prev => {
      const newSelection = prev.includes(metricName)
        ? prev.filter(m => m !== metricName)
        : [metricName, ...prev];
      
      onFilterChange(newSelection);
      return newSelection;
    });
  };

  const sortedMetrics = useMemo(() => {
    return [...metrics].sort((a, b) => {
      const aSelected = selectedMetrics.includes(a.name);
      const bSelected = selectedMetrics.includes(b.name);
      if (aSelected === bSelected) {
        return selectedMetrics.indexOf(a.name) - selectedMetrics.indexOf(b.name);
      }
      return bSelected - aSelected;
    });
  }, [metrics, selectedMetrics]);

  const handleMouseEnter = () => {
    if (!isMobile) {
      setShowAllFilters(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setShowAllFilters(false);
    }
  };

  return (
    <div 
      className={`${styles.filterContainer} ${isMobile ? styles.mobile : ''} ${showAllFilters ? styles.expanded : ''}`}
      ref={filterContainerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-semibold">Top Evaluation Metrics</h2>
        {isMobile && sortedMetrics.length > visibleCount && (
          <button 
            onClick={() => setShowAllFilters(!showAllFilters)} 
            className="text-blue-500 underline text-xs"
          >
            {showAllFilters ? "hide extra" : "show all"}
          </button>
        )}
      </div>
      <div className="flex flex-wrap gap-2">
        {isLoading ? (
          Array(5).fill().map((_, index) => (
            <LoadingBadge key={index} />
          ))
        ) : (
          (showAllFilters || !isMobile ? sortedMetrics : sortedMetrics.slice(0, visibleCount)).map(({ name, count }) => (
            <Badge 
              key={name}
              active={selectedMetrics.includes(name)}
              onClick={() => toggleMetric(name)}
            >
              {name} ({count})
            </Badge>
          ))
        )}
      </div>
    </div>
  );
};

export default TopMetricsFilter;