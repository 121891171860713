import React from 'react';
import { Link } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';

import { useAuth } from '../context/AuthContext';

const FavoritedArticles = ({ favorites, onRemoveFavorite }) => {
  const { user } = useAuth();

  const truncateAuthors = (authors) => {
    if (authors.length <= 3) return authors.join(', ');
    return `${authors.slice(0, 3).join(', ')} et al.`;
  };

  return (
    <div className="mb-8 hidden sm:block">
      <h2 className="text-sm font-bold text-gray-900 mb-4 flex items-center">
        <StarIcon className="h-4 w-6 text-yellow-400 mr-2" />
        Favorited Articles
      </h2>
      {favorites.length === 0 ? (
        <p className="text-gray-600">No favorites found. Try adding some!</p>
      ) : (
        <ul className="space-y-4">
          {favorites.map(({paper}) => (
            <li key={paper.paper_id} className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-shadow duration-300 relative">
              <Link to={`/paper/${paper?.paper_id}`} className="block pr-8">
                <h3 className="text-lg font-semibold text-blue-600 hover:underline mb-2">
                  {paper.title || 'Untitled'}
                </h3>
                <p className="text-sm text-gray-600">
                  {paper.authors && paper.authors.length > 0
                    ? truncateAuthors(paper.authors)
                    : 'Unknown authors'}
                </p>
              </Link>
              {!!user && (
                <button
                  onClick={() => onRemoveFavorite(paper.paper_id)}
                  className="absolute bottom-2 right-2 text-gray-300 hover:text-red-500 transition-colors duration-300"
                  title="Remove from favorites"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FavoritedArticles;