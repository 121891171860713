import React from 'react';
import Blur from '../ui/Blur';

const Table = ({ data, isAuthenticated, applyBlur = true }) => (
  <table className="w-full mb-4">
    <tbody>
      {Object.entries(data).map(([key, value]) => (
        <tr key={key} className="border-b border-gray-200">
          <th className="text-left py-2 pr-4 bg-gray-100 text-gray-700">{key}</th>
          <td className="py-2 pl-4">
            {key === "Paper ID" && typeof value === 'object' ? (
              <a href={value.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                {value.id}
              </a>
            ) : (
              <Blur enabled={!isAuthenticated && applyBlur} hoverMessage={`Login to view ${key.toLowerCase()}`}>
                {value !== null && value !== undefined ? value : 'N/A'}
              </Blur>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default Table;